const EMPTY_VALUE_PLACEHOLDER = "--";
const STOCK_SPLIT = "StockSplit";

const EVENT_NAME_COLUMN_ID = "event";

const CompanyTabs = {
  PriceVolume: "Price and $ Volume",
  BidAskPrice: "Bid-Ask Price",
  ReportedMarks: "Reported Marks",
  BidAskRatio: "Bid-Ask Ratio",
  RelativeActivity: "Relative Activity",
  CompanyProfile: "Company Profile",
  CompanyNews: "News",
  Valuation: "Valuation",
};

const ERROR_MESSAGE = "An error has occurred.";

const companyProfileBreakpoint = 1600;

const FETCH_NEXT_COMPANIES_PAGE_THRESHOLD = 100;
const MIN_SEARCH_QUERY_LENGTH = 1;

const VALUATION_COMPONENT_OTHER = 0;
const VALUATION_COMPONENT_ZX_INDEX_VALUE = 10;
const VALUATION_COMPONENT_ZX_INDEX_VALUE_TRAILING = 20;
const VALUATION_COMPONENT_REPORTED_MARKS = 30;
const VALUATION_COMPONENT_FUNDING_ROUND = 40;
const VALUATION_COMPONENT_PRIVATE_MARKET_INDEX_COMPARISON = 50;
const VALUATION_COMPONENT_COMPANY_COMPARISON = 60;

const ZX_EQUAL_WEIGHTED_INDEX_SYMBOL = "ZXEQ";

export {
  ERROR_MESSAGE,
  EVENT_NAME_COLUMN_ID,
  CompanyTabs,
  companyProfileBreakpoint,
  STOCK_SPLIT,
  EMPTY_VALUE_PLACEHOLDER,
  FETCH_NEXT_COMPANIES_PAGE_THRESHOLD,
  MIN_SEARCH_QUERY_LENGTH,
  VALUATION_COMPONENT_OTHER,
  VALUATION_COMPONENT_ZX_INDEX_VALUE,
  VALUATION_COMPONENT_ZX_INDEX_VALUE_TRAILING,
  VALUATION_COMPONENT_REPORTED_MARKS,
  VALUATION_COMPONENT_FUNDING_ROUND,
  VALUATION_COMPONENT_PRIVATE_MARKET_INDEX_COMPARISON,
  VALUATION_COMPONENT_COMPANY_COMPARISON,
  ZX_EQUAL_WEIGHTED_INDEX_SYMBOL,
};
