import _ from "lodash";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React, { useCallback, useMemo, useState } from "react";
import { Bar, BarChart, Tooltip, XAxis, ResponsiveContainer } from "recharts";
import styled from "styled-components";
import { ColorPalette, Fonts } from "yuka";

import ActiveVolumeBar from "./ActiveVolumeBar";
import { SELECTED_RATIO_TYPE_VOLUME } from "./constants";
import SelectTypeDropdown from "./SelectTypeDropdown";

import { API_ENDPOINTS } from "../../../api/constants";
import useFetch from "../../../api/useFetch";
import { DataverseColors } from "../../../hdYuka/constants";
import SurfaceZeroCard from "../../../hdYuka/SurfaceZeroCard";
import applyOpacityToHex from "../../../utils/applyOpacityToHex";
import { ORDER_FLOW_TIME_FRAME_MONTHLY } from "../../../utils/constants";
import { percentFormat } from "../../../utils/displayFormatUtils";
import LoadingSpinner from "../../../utils/LoadingSpinner";
import MixpanelEvents from "../../../utils/mixpanel/MixpanelEvents";
import { useCompany } from "../../hooks";
import {
  List,
  ListItem,
  StyledCircle,
  StyledEmptyPill,
  StyledCenteredEmptyState,
} from "../../StyledComponents";

const GraphXAxisYearContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;

  > :last-child {
    margin-top: 24px;
  }
`;

const LegendItemContainer = styled(Fonts.Body1theme50).attrs({ as: "div" })`
  display: flex;
  gap: 12px;
  align-items: center;
`;

// Debounce hover tracker by 1 second
const HOVER_INTERACTION_DEBOUNCE_DELAY = 1000;
const hoverCompanyBidOfferRatioCard = _.debounce(
  MixpanelEvents.hoverCompanyBidOfferRatioCard,
  HOVER_INTERACTION_DEBOUNCE_DELAY
);

const BidAskRatioStatsList = ({
  bidPercentage,
  askPercentage,
  reportPeriod,
}) => (
  <List>
    <ListItem>
      <LegendItemContainer>
        <StyledCircle color={DataverseColors.green} />
        Bid %
      </LegendItemContainer>
      {bidPercentage !== undefined ? (
        <Fonts.Body2theme80>
          {percentFormat(bidPercentage * 100, 0)}
        </Fonts.Body2theme80>
      ) : (
        <Fonts.Body2theme30>--</Fonts.Body2theme30>
      )}
    </ListItem>
    <ListItem>
      <LegendItemContainer>
        <StyledCircle color={DataverseColors.red} />
        Ask %
      </LegendItemContainer>
      {askPercentage !== undefined ? (
        <Fonts.Body2theme80>
          {percentFormat(askPercentage * 100, 0)}
        </Fonts.Body2theme80>
      ) : (
        <Fonts.Body2theme30>--</Fonts.Body2theme30>
      )}
    </ListItem>
    <ListItem>
      <LegendItemContainer>Report Period</LegendItemContainer>
      {reportPeriod !== undefined ? (
        <Fonts.Body2theme80>
          {DateTime.fromISO(reportPeriod).toLocaleString({
            month: "short",
            year: "numeric",
          })}
        </Fonts.Body2theme80>
      ) : (
        <Fonts.Body2theme30>--</Fonts.Body2theme30>
      )}
    </ListItem>
  </List>
);

BidAskRatioStatsList.propTypes = {
  bidPercentage: PropTypes.number,
  askPercentage: PropTypes.number,
  reportPeriod: PropTypes.string,
};

const BidAskRatioCard = () => {
  const [hoverStats, setHoverStats] = useState();
  const [company, companyIsLoading] = useCompany();
  const [selectedRatioType, _setSelectedRatioType] = useState(
    SELECTED_RATIO_TYPE_VOLUME
  );
  const setSelectedRatioType = useCallback(
    (type) => {
      _setSelectedRatioType(type);
      MixpanelEvents.selectBidOfferRatioType(company?.name, type);
    },
    [_setSelectedRatioType, company]
  );
  const selectedBidType = useMemo(
    () =>
      selectedRatioType === SELECTED_RATIO_TYPE_VOLUME
        ? "percent_bid_by_volume"
        : "percent_bid_by_tickets",
    [selectedRatioType]
  );
  const selectedAskType = useMemo(
    () =>
      selectedRatioType === SELECTED_RATIO_TYPE_VOLUME
        ? "percent_ask_by_volume"
        : "percent_ask_by_tickets",
    [selectedRatioType]
  );

  const bidOfferRatioQuery = useFetch(
    `${API_ENDPOINTS.COMPANY_BID_OFFER_RATIO_DATA(company.zb_id, {
      time_frame: ORDER_FLOW_TIME_FRAME_MONTHLY,
    })}?time_frame=${ORDER_FLOW_TIME_FRAME_MONTHLY}`
  );
  const bidOfferRatioData = bidOfferRatioQuery?.data?.data;
  const graphData = useMemo(
    () =>
      _.map(bidOfferRatioData, (d) => ({
        date: d.attributes.report_period,
        percent_bid_by_tickets: Number(d.attributes.percent_bid_by_tickets),
        percent_ask_by_tickets: 1 - d.attributes.percent_bid_by_tickets,
        percent_bid_by_volume: Number(d.attributes.percent_bid_by_volume),
        percent_ask_by_volume: 1 - d.attributes.percent_bid_by_volume,
      })).reverse(),
    [bidOfferRatioData]
  );

  const xAxisTicks = useMemo(() => _.map(graphData, "date"), [graphData]);
  const startDate = useMemo(() => _.first(xAxisTicks), [xAxisTicks]);
  const endDate = useMemo(() => _.last(xAxisTicks), [xAxisTicks]);

  // Set hover point data
  const onMouseMove = useCallback(
    (d) => {
      if (!graphData) {
        return;
      }

      hoverCompanyBidOfferRatioCard(company?.name, d.activeLabel);
      const hoveredBarData = graphData[d.activeTooltipIndex];
      setHoverStats({
        ...hoveredBarData,
        report_period: d.activeLabel,
        index: d.activeTooltipIndex,
      });
    },
    [graphData, company?.name]
  );
  // Remove hover stats on mouse leave
  const onMouseLeave = useCallback(() => setHoverStats(null), []);
  // Identical shape to hoverStats, used to display default legend value if no bar is being hovered
  const lastPeriodStats = useMemo(() => {
    const lastPeriodData = _.last(graphData);
    return {
      ...lastPeriodData,
      report_period: lastPeriodData?.date,
      index: _.isEmpty(graphData) ? null : _.size(graphData) - 1,
    };
  }, [graphData]);
  const displayStats = useMemo(
    () => hoverStats || lastPeriodStats || null,
    [hoverStats, lastPeriodStats]
  );

  // The active dot on the <Line /> component requires a tooltip to be enabled
  // So we pass in a null tooltip function as a hack
  const renderNullTooltip = useCallback(() => null, []);

  if (companyIsLoading) {
    return <LoadingSpinner />;
  }

  if (!bidOfferRatioData) {
    return (
      <SurfaceZeroCard title="Bid-Ask Ratio">
        <StyledContent>
          <StyledEmptyPill>
            <StyledCenteredEmptyState $margin={48}>
              Data not available
            </StyledCenteredEmptyState>
          </StyledEmptyPill>
          <BidAskRatioStatsList />
        </StyledContent>
      </SurfaceZeroCard>
    );
  }

  return (
    <SurfaceZeroCard
      title="Bid-Ask Ratio"
      headerTrailingContent={
        <SelectTypeDropdown
          selectedRatioType={selectedRatioType}
          setSelectedRatioType={setSelectedRatioType}
        />
      }
    >
      <StyledContent>
        <ResponsiveContainer width="100%" minHeight={108}>
          <BarChart
            data={graphData}
            barCategoryGap={8}
            onMouseMove={onMouseMove}
            onMouseLeave={onMouseLeave}
            margin={{
              top: 0,
              right: -7,
              left: -7,
              bottom: 0,
            }}
          >
            <XAxis
              axisLine={false}
              tickLine={false}
              interval={0}
              fontSize={11}
              tickFormatter={(value) => DateTime.fromISO(value).toFormat("MMM")}
              ticks={xAxisTicks}
              dataKey="date"
              stroke={ColorPalette.white50}
            />
            <Tooltip
              content={renderNullTooltip}
              cursor={{ fill: "transparent" }}
            />
            <Bar
              dataKey={selectedBidType}
              fill={applyOpacityToHex(DataverseColors.green, 0.5)}
              stackId="bidAskRatioStack"
              activeBar={ActiveVolumeBar(
                selectedBidType,
                DataverseColors.green,
                0
              )}
              barSize={12}
              activeIndex={displayStats.index}
            />
            <Bar
              dataKey={selectedAskType}
              fill={applyOpacityToHex(DataverseColors.red, 0.5)}
              stackId="bidAskRatioStack"
              style={{ transform: "translateY(-2px)" }}
              activeBar={ActiveVolumeBar(
                selectedAskType,
                DataverseColors.red,
                1
              )}
              activeIndex={displayStats.index}
              barSize={12}
            />
          </BarChart>
        </ResponsiveContainer>
        <GraphXAxisYearContainer>
          <Fonts.Caption1theme50>
            {DateTime.fromISO(startDate).year}
          </Fonts.Caption1theme50>
          <Fonts.Caption1theme50>
            {DateTime.fromISO(endDate).year}
          </Fonts.Caption1theme50>
        </GraphXAxisYearContainer>
        <BidAskRatioStatsList
          bidPercentage={displayStats?.[selectedBidType]}
          askPercentage={displayStats?.[selectedAskType]}
          reportPeriod={displayStats?.report_period}
        />
      </StyledContent>
    </SurfaceZeroCard>
  );
};

export default BidAskRatioCard;
