import _ from "lodash";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useForm, useFormState } from "react-final-form";
import styled from "styled-components";
import {
  Avatar,
  BuildingIcon,
  FinalFormField,
  List,
  ListItem,
  SearchIcon,
  useDropdown,
  XCloseIcon,
  Fonts,
  ColorPalette,
  IconButton,
} from "yuka";

import AbandonShareLotsModal from "./AbandonShareLotsModal";
import {
  COMPANY_SEARCH_FORM_FIELD_NAME,
  COMPANY_MATCH_FIELD_NAME,
  SELECTED_COMPANY_ID_FIELD_NAME,
  COMPANY_MATCH_NO_MATCHES,
  COMPANY_MATCH_ALREADY_IN_PORTFOLIO,
  COMPANY_MATCH_HAS_RESULTS,
} from "./constants";
import { usePortfolio, usePortfolioCompanies } from "./hooks";
import { isEmptyShareLot, getPortfolioCompanyName } from "./utils";

import { API_ENDPOINTS } from "../api/constants";
import useFetch from "../api/useFetch";
import useInfiniteFetch from "../api/useInfiniteFetch";
import { MIN_SEARCH_QUERY_LENGTH } from "../company/constants";
import { WEEKLY } from "../utils/constants";
import LoadingSpinner from "../utils/LoadingSpinner";
import useScrollable from "../utils/useScrollable";

const StyledFlex = styled.div`
  display: flex;
  gap: 8px;
`;

const StyledSearchBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
`;

const StyledSearchBar = styled(FinalFormField)`
  width: 100%;
`;

const StyledSelectedCompanyContainer = styled.div`
  height: 40px;
  padding: 0 12px;
  width: 100%;
  flex-shrink: 0;
  border-radius: 8px;
  box-sizing: border-box;
  border: 1px solid ${ColorPalette.white15};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledDropdownContainer = styled.div`
  overflow-y: auto;
  max-height: 240px;
  margin: -16px 0;
  width: 752px;
`;

const StyledListItem = styled(ListItem)`
  // I am very important. Overriding Yuka-injected styles.
  border-bottom: 1px dashed ${ColorPalette.white15} !important;
`;

const FETCH_NEXT_COMPANIES_PAGE_THRESHOLD = 80;

const getGenericCompanyId = (genericCompany) =>
  genericCompany.company || genericCompany.requested_company;
const getPortfolioCompanyId = (portfolioCompany) =>
  portfolioCompany.company?.zb_id || portfolioCompany.requested_company?.id;

const PortfolioCompanySearch = () => {
  const [abandonShareLotsModalShowing, setAbandonShareLotsModalShowing] =
    useState(false);
  const [portfolio] = usePortfolio();
  const [portfolioCompanies, portfolioCompaniesLoading] = usePortfolioCompanies(
    portfolio?.apiId
  );

  const {
    values: {
      [COMPANY_SEARCH_FORM_FIELD_NAME]: searchQuery,
      [SELECTED_COMPANY_ID_FIELD_NAME]: selectedCompanyId,
      shareLots,
    },
  } = useFormState();
  const { change, batch } = useForm();
  const searchBarRef = useRef(null);
  const scrollableListRef = useRef(null);
  const showSearchResults = useMemo(
    () => searchQuery?.length >= MIN_SEARCH_QUERY_LENGTH,
    [searchQuery]
  );

  const mostActiveCompaniesQuery = useFetch(
    API_ENDPOINTS.COMPANY_LATEST_ORDER_FLOW(),
    {
      time_frame: WEEKLY,
      most_active: true,
      "page[size]": 5,
    }
  );

  const mostActiveCompanies = useMemo(() => {
    if (mostActiveCompaniesQuery.isSuccess) {
      return mostActiveCompaniesQuery.cleanedData.data;
    }
    return [];
  }, [
    mostActiveCompaniesQuery.isSuccess,
    mostActiveCompaniesQuery.cleanedData,
  ]);

  const genericCompanyQuery = useInfiniteFetch(
    API_ENDPOINTS.GENERIC_COMPANY(),
    { search: searchQuery },
    { enabled: showSearchResults }
  );

  const onScrollDownFetchNext = useCallback(
    (distanceFromBottom) => {
      if (
        distanceFromBottom < FETCH_NEXT_COMPANIES_PAGE_THRESHOLD &&
        genericCompanyQuery.hasNextPage &&
        !genericCompanyQuery.isFetchingNextPage
      ) {
        genericCompanyQuery.fetchNextPage().then((response) => response.data);
      }
    },
    [genericCompanyQuery]
  );

  useScrollable({
    scrollRef: scrollableListRef,
    onScrollUp: _.noop,
    onScrollDown: onScrollDownFetchNext,
  });

  const companies = useMemo(() => {
    // Returns either the results from the API fetch, or the local storage companies that were
    // recently visited.
    if (showSearchResults) {
      return genericCompanyQuery.isSuccess
        ? genericCompanyQuery.cleanedData.data
        : [];
    }
    return mostActiveCompanies.map((company) => ({
      company: company.apiId,
      name: company.name,
      main_picture: company.main_picture,
      legal_name: company.legal_name,
    }));
  }, [genericCompanyQuery, showSearchResults, mostActiveCompanies]);

  const selectedCompany = useMemo(() => {
    if (!genericCompanyQuery.isSuccess || !selectedCompanyId) {
      return null;
    }
    return companies.find(
      (company) => getGenericCompanyId(company) === selectedCompanyId
    );
  }, [genericCompanyQuery.isSuccess, selectedCompanyId, companies]);

  const SearchDropdown = () => (
    <StyledDropdownContainer ref={scrollableListRef}>
      <List>
        {mostActiveCompaniesQuery.isLoading && (
          <ListItem text="Loading most active companies..." />
        )}
        {companies.map((company) => {
          const alreadyAdded =
            !portfolioCompaniesLoading &&
            portfolioCompanies.find(
              // Making use of UUID collision likelihood
              (portfolioCompany) =>
                getPortfolioCompanyId(portfolioCompany) ===
                getGenericCompanyId(company)
            );

          return (
            <StyledListItem
              key={`${getGenericCompanyId(company)}`}
              avatar={
                company.main_picture ? (
                  <Avatar
                    avatar={company.main_picture}
                    avatarAlt={company.name}
                    size="24px"
                  />
                ) : null
              }
              trailingContent={
                alreadyAdded ? (
                  <Fonts.Body1theme50>Already in portfolio</Fonts.Body1theme50>
                ) : null
              }
              leadingIcon={BuildingIcon}
              text={company.name}
              onClick={
                alreadyAdded
                  ? () => {}
                  : () => {
                      batch(() => {
                        change(COMPANY_SEARCH_FORM_FIELD_NAME, company.name);
                        change(
                          SELECTED_COMPANY_ID_FIELD_NAME,
                          getGenericCompanyId(company)
                        );
                      });
                    }
              }
            />
          );
        })}
      </List>
      {genericCompanyQuery.isFetching && <LoadingSpinner />}
    </StyledDropdownContainer>
  );

  const [dropdownElement, dropdownRef, toggleDropdown] =
    useDropdown(SearchDropdown);

  /*
   * We will auto set the company ID to the first company in the query results if the user's
   * query perfectly matches the company's name, this way we won't require the user to select
   * the company if they typed the name all the way out. Anything other than a perfect match
   * needs to be selected in the dropdown. We also don't auto-select the option if the user already
   * has that company in their portfolio.
   */
  useEffect(() => {
    if (
      selectedCompanyId === null &&
      searchQuery &&
      genericCompanyQuery.isSuccess &&
      companies[0]?.name.toLowerCase().trim() ===
        searchQuery.toLowerCase().trim() &&
      !dropdownElement &&
      !portfolioCompanies.find(
        (company) =>
          getPortfolioCompanyName(company).toLowerCase().trim() ===
          searchQuery.toLowerCase().trim()
      )
    ) {
      change(SELECTED_COMPANY_ID_FIELD_NAME, getGenericCompanyId(companies[0]));
    }
  }, [
    selectedCompanyId,
    portfolioCompanies,
    dropdownElement,
    searchQuery,
    genericCompanyQuery.isSuccess,
    companies,
    change,
  ]);

  /*
   * We'll indicate to the user that the company they're searching for doesn't exist in 2 cases:
   * 1. The user has a search query, the query has executed and there are 0 results
   * 2. The user has a query, is not focussed on the search box, and did not select one of the
   *    available companies.
   *
   * We'll also indicate if the user has manually typed in a company name that's already in their
   * portfolio.
   */
  useEffect(() => {
    if (
      searchQuery &&
      genericCompanyQuery.isSuccess &&
      companies.length === 0
    ) {
      change(COMPANY_MATCH_FIELD_NAME, COMPANY_MATCH_NO_MATCHES);
    } else if (searchQuery && !dropdownElement && selectedCompanyId === null) {
      if (
        portfolioCompanies.find(
          (company) =>
            getPortfolioCompanyName(company).toLowerCase().trim() ===
            searchQuery.toLowerCase().trim()
        )
      ) {
        change(COMPANY_MATCH_FIELD_NAME, COMPANY_MATCH_ALREADY_IN_PORTFOLIO);
      } else {
        change(COMPANY_MATCH_FIELD_NAME, COMPANY_MATCH_NO_MATCHES);
      }
    } else {
      change(COMPANY_MATCH_FIELD_NAME, COMPANY_MATCH_HAS_RESULTS);
    }
  }, [
    searchQuery,
    genericCompanyQuery.isSuccess,
    companies.length,
    portfolioCompanies,
    change,
    selectedCompanyId,
    dropdownElement,
  ]);

  const handleFocus = useCallback(() => {
    if (!dropdownElement) {
      toggleDropdown();
    }
  }, [dropdownElement, toggleDropdown]);

  const handleBlur = useCallback(
    (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.relatedTarget) &&
        dropdownElement
      ) {
        toggleDropdown();
      }
    },
    [dropdownRef, toggleDropdown, dropdownElement]
  );

  useEffect(() => {
    const current = dropdownRef.current;

    if (current) {
      current.addEventListener("focusin", handleFocus);
      current.addEventListener("focusout", handleBlur);
    }
    return () => {
      if (current) {
        current.removeEventListener("focusin", handleFocus);
        current.removeEventListener("focusout", handleBlur);
      }
    };
  }, [dropdownRef, handleFocus, handleBlur]);

  useEffect(() => {
    // When the dropdown element is showing, focus the search bar so typing works.
    if (dropdownElement) {
      searchBarRef.current?.focus();
    }
  }, [dropdownElement, searchBarRef]);

  useEffect(() => {
    // Defaults to displaying search results when the component first loads.
    if (!selectedCompanyId) {
      searchBarRef.current?.focus();
    }
  }, [selectedCompanyId, searchBarRef]);

  return (
    <>
      {selectedCompany && (
        <StyledSelectedCompanyContainer>
          <StyledFlex>
            {selectedCompany.main_picture ? (
              <Avatar
                avatar={selectedCompany.main_picture}
                avatarAlt={selectedCompany.name.slice(0, 2).toUpperCase()}
                size="24px"
              />
            ) : (
              <BuildingIcon size={24} color={ColorPalette.white50} />
            )}
            {selectedCompany.name}
          </StyledFlex>
          <IconButton
            icon={XCloseIcon}
            onClick={() => {
              if (shareLots.every(isEmptyShareLot)) {
                // When no modal confirmation is needed.
                batch(() => {
                  change(COMPANY_SEARCH_FORM_FIELD_NAME, "");
                  change(SELECTED_COMPANY_ID_FIELD_NAME, null);
                  change("shareLots", [{ tempId: Math.random() }]);
                });
                toggleDropdown();
              } else {
                setAbandonShareLotsModalShowing(true);
              }
            }}
          />
        </StyledSelectedCompanyContainer>
      )}
      <StyledSearchBarContainer ref={dropdownRef}>
        <StyledSearchBar
          type={selectedCompany ? "hidden" : "text"}
          ref={searchBarRef}
          options={companies}
          id="company-search"
          leadingIcon={SearchIcon}
          name={COMPANY_SEARCH_FORM_FIELD_NAME}
          placeholder="Add company"
        />
        {((genericCompanyQuery.isSuccess && companies.length !== 0) ||
          genericCompanyQuery.isIdle) &&
          dropdownElement}
      </StyledSearchBarContainer>
      {abandonShareLotsModalShowing && (
        <AbandonShareLotsModal
          onClose={() => {
            setAbandonShareLotsModalShowing(false);
          }}
          abandonShareLots={() => {
            if (!dropdownElement) {
              toggleDropdown();
            }
            batch(() => {
              change(COMPANY_SEARCH_FORM_FIELD_NAME, "");
              change(SELECTED_COMPANY_ID_FIELD_NAME, null);
              change("shareLots", [{ tempId: Math.random() }]);
            });
            setAbandonShareLotsModalShowing(false);
          }}
        />
      )}
    </>
  );
};

export default PortfolioCompanySearch;
