import { DateTime } from "luxon";
import { ColorPalette } from "yuka";

import LegendItem from "../CompanyProfile/CompanyProfileLineGraph/LegendItem";
import VolumeAggregateLegendItem from "../CompanyProfile/CompanyProfileLineGraph/VolumeAggregateLegendItem";

const ZX_COMPANY_EXCHANGE = "ZX";
const ZX_MARKET_INDEX = "ZXI";

const WEEKS_IN_3_MONTHS = 13;
const WEEKS_IN_6_MONTHS = 26;
const WEEKS_IN_1_YEAR = 52;
const THREE_MONTHS_KEY = "3M";
const SIX_MONTHS_KEY = "6M";
const ONE_YEAR_KEY = "1Y";
const TWO_YEAR_KEY = "2Y";
const YTD_KEY = "YTD";

const API_TYPE_PRIVATE_COMPANY = "Company";
const API_TYPE_PRIVATE_MARKET_INDEX = "PrivateMarketIndex";
const API_TYPE_PUBLIC_SECURITY = "PublicSecurity";

const KEY_COMPANY_SERIES_TYPE = "KEY_COMPANY";
const ZX_COMPARISON_SERIES_TYPE = "ZX_COMPARISON";
const ZX_MARKET_INDEX_SERIES_COMPARISON_TYPE = "ZX_MARKET_INDEX_COMPARISON";
const PUBLIC_COMPARISON_SERIES_TYPE = "PUBLIC_COMPARISON";

const API_TYPE_TO_SERIES_TYPE = {
  [API_TYPE_PRIVATE_COMPANY]: ZX_COMPARISON_SERIES_TYPE,
  [API_TYPE_PRIVATE_MARKET_INDEX]: ZX_MARKET_INDEX_SERIES_COMPARISON_TYPE,
  [API_TYPE_PUBLIC_SECURITY]: PUBLIC_COMPARISON_SERIES_TYPE,
};

// Describes the names of the fields in the price API endpoints for private and public securities
const SUPERCHART_API_DATA_FIELDS = {
  [KEY_COMPANY_SERIES_TYPE]: {
    TIME_FIELD: "report_period",
    PRICE_DATA_FIELD: "zx_index_price",
    ADDITIONAL_DATA_FIELDS: ["robustness", "volume"],
  },
  [ZX_COMPARISON_SERIES_TYPE]: {
    TIME_FIELD: "report_period",
    PRICE_DATA_FIELD: "zx_index_price",
    ADDITIONAL_DATA_FIELDS: ["robustness"],
  },
  [ZX_MARKET_INDEX_SERIES_COMPARISON_TYPE]: {
    TIME_FIELD: "created_on",
    PRICE_DATA_FIELD: "value",
    ADDITIONAL_DATA_FIELDS: [],
    config: {
      castDateTimeToDate: true,
    },
  },
  [PUBLIC_COMPARISON_SERIES_TYPE]: {
    TIME_FIELD: "date",
    PRICE_DATA_FIELD: "close_price",
    ADDITIONAL_DATA_FIELDS: [],
  },
};

const PRICE_DATA_START_DATES = {
  [THREE_MONTHS_KEY]: DateTime.now().minus({ weeks: WEEKS_IN_3_MONTHS }),
  [SIX_MONTHS_KEY]: DateTime.now().minus({ weeks: WEEKS_IN_6_MONTHS }),
  [ONE_YEAR_KEY]: DateTime.now().minus({ weeks: WEEKS_IN_1_YEAR }),
  [YTD_KEY]: DateTime.now().startOf("year"),
};

const INDICATOR_CATEGORY_BID_OFFER = "Bid & Ask";
const INDICATOR_CATEGORY_OVERALL = "Overall";

const getAbsoluteIndicatorValue = (id, indicatorDataPoint) => ({
  [id]: indicatorDataPoint.absolute,
});

const getBidOfferValue = (id, indicatorDataPoint) => {
  return {
    [`${id}_bid`]:
      indicatorDataPoint.bid !== null ? Number(indicatorDataPoint.bid) : null,
    [`${id}_offer`]:
      indicatorDataPoint.offer !== null
        ? Number(indicatorDataPoint.offer)
        : null,
  };
};

const OLD_INDICATOR_TYPES = {
  ZIVT: {
    name: "ZIVT",
    label: "ZX Index Value - Trailing",
    apiDataKey: "zx_index_value_trailing",
    category: INDICATOR_CATEGORY_OVERALL,
  },
  DWA: {
    name: "DWA",
    label: "Dollar-Weighted AVG.",
    apiDataKey: "overall_dwa",
    category: INDICATOR_CATEGORY_OVERALL,
  },
  SA: {
    name: "SA",
    label: "Simple AVG.",
    apiDataKey: "overall_simple_average",
    category: INDICATOR_CATEGORY_OVERALL,
  },
  V3MA: {
    name: "V3MA",
    label: "Volume - 3M AGG.",
    apiDataKey: "total_volume",
    category: INDICATOR_CATEGORY_OVERALL,
  },
  BZX: {
    name: "BZX",
    label: "Bid ZX Index Value",
    apiDataKey: "zx_index_value_bid_side",
    category: INDICATOR_CATEGORY_BID_OFFER,
  },
  BDWA: {
    name: "BDWA",
    label: "Bid Dollar-Weighted AVG.",
    apiDataKey: "bid_dwa",
    category: INDICATOR_CATEGORY_BID_OFFER,
  },
  BSA: {
    name: "BSA",
    label: "Bid Simple AVG.",
    apiDataKey: "bid_simple_average",
    category: INDICATOR_CATEGORY_BID_OFFER,
  },
  OZX: {
    name: "OZX",
    label: "Offer ZX Index Value",
    apiDataKey: "zx_index_value_ask_side",
    category: INDICATOR_CATEGORY_BID_OFFER,
  },
  ODWA: {
    name: "ODWA",
    label: "Offer Dollar-Weighted AVG.",
    apiDataKey: "ask_dwa",
    category: INDICATOR_CATEGORY_BID_OFFER,
  },
  OSA: {
    name: "OSA",
    label: "Offer Simple AVG.",
    apiDataKey: "ask_simple_average",
    category: INDICATOR_CATEGORY_BID_OFFER,
  },
};

// Indicators that come from orderflow reports; apiDataKey refers to their api field name.
// Note that the ordering here is important, as it determines the order in which the indicators
// are displayed in the "select an indicator" dropdown.
const INDICATOR_TYPES = {
  ZIVT: {
    name: "ZX Index Value - Trailing",
    apiDataKey: "zx_index_value_trailing",
    category: INDICATOR_CATEGORY_OVERALL,
    LegendItem: LegendItem,
  },
  DWA: {
    name: "Dollar-Weighted AVG.",
    apiDataKey: "overall_dwa",
    category: INDICATOR_CATEGORY_OVERALL,
    LegendItem: LegendItem,
  },
  SA: {
    name: "Simple AVG.",
    apiDataKey: "overall_simple_average",
    category: INDICATOR_CATEGORY_OVERALL,
    LegendItem: LegendItem,
  },
  V3MA: {
    name: "Volume - 3M AGG.",
    apiDataKey: "total_volume",
    isVolume: true,
    category: INDICATOR_CATEGORY_OVERALL,
    getIndicatorValueObject: getBidOfferValue,
    LegendItem: VolumeAggregateLegendItem,
  },
  BZX: {
    name: "Bid ZX Index Value",
    apiDataKey: "zx_index_value_bid_side",
    category: INDICATOR_CATEGORY_BID_OFFER,
    LegendItem: LegendItem,
  },
  BDWA: {
    name: "Bid Dollar-Weighted AVG.",
    apiDataKey: "bid_dwa",
    category: INDICATOR_CATEGORY_BID_OFFER,
    LegendItem: LegendItem,
  },
  BSA: {
    name: "Bid Simple AVG.",
    apiDataKey: "bid_simple_average",
    category: INDICATOR_CATEGORY_BID_OFFER,
    LegendItem: LegendItem,
  },
  OZX: {
    name: "Offer ZX Index Value",
    apiDataKey: "zx_index_value_ask_side",
    category: INDICATOR_CATEGORY_BID_OFFER,
    LegendItem: LegendItem,
  },
  ODWA: {
    name: "Offer Dollar-Weighted AVG.",
    apiDataKey: "ask_dwa",
    category: INDICATOR_CATEGORY_BID_OFFER,
    LegendItem: LegendItem,
  },
  OSA: {
    name: "Offer Simple AVG.",
    apiDataKey: "ask_simple_average",
    category: INDICATOR_CATEGORY_BID_OFFER,
    LegendItem: LegendItem,
  },
};

const REVERSE_INDICATOR_TYPES = {
  zx_index_value_bid_side: "BZX",
  zx_index_value_ask_side: "OZX",
  overall_dwa: "DWA",
  overall_simple_average: "SA",
  bid_dwa: "BDWA",
  bid_simple_average: "BSA",
  ask_dwa: "ODWA",
  ask_simple_average: "OSA",
};

const COMPARISON_COLORS = [
  // Set 1
  "#A60683",
  "#D63CB4",
  "#FF82E3",
  "#EAB6DE",
  "#E4CFE0",
  // Set 2
  "#1D4CC3",
  "#346DFF",
  "#6B8BDE",
  "#8290B4",
  // Set 3
  "#964800",
  "#C86912",
  "#FF993C",
  "#615D00",
  "#A6A80B",
  "#FFFA79",
  "#E5E4BE",
];

const KEY_COMPANY_COLOR = ColorPalette.accent;

const INDICATOR_COLORS = [
  ColorPalette.blue300,
  ColorPalette.blue400,
  ColorPalette.blue600,
  ColorPalette.blue700,
  ColorPalette.blue800,
  ColorPalette.blue900,
];

const LEGEND_Z_INDEX = 3; // Yuka z-indices are either too low or too high
const CHART_RIGHT_OFFSET_MULTIPLIER = 0.035;

const EXCHANGE_DISPLAY_NAMES = {
  ZXI: "ZX",
  XNAS: "NASDAQ",
  XNYS: "NYSE",
};

const KEY_COMPANY = "KEY_COMPANY";
const INDICATOR = "INDICATOR";
const COMPARISON = "COMPARISON";

const SERIES_TYPES = {
  KEY_COMPANY,
  INDICATOR,
  COMPARISON,
};
export {
  getAbsoluteIndicatorValue,
  API_TYPE_PUBLIC_SECURITY,
  API_TYPE_PRIVATE_MARKET_INDEX,
  API_TYPE_PRIVATE_COMPANY,
  API_TYPE_TO_SERIES_TYPE,
  ZX_COMPANY_EXCHANGE,
  ZX_MARKET_INDEX,
  ZX_MARKET_INDEX_SERIES_COMPARISON_TYPE,
  THREE_MONTHS_KEY,
  SIX_MONTHS_KEY,
  ONE_YEAR_KEY,
  TWO_YEAR_KEY,
  YTD_KEY,
  KEY_COMPANY_SERIES_TYPE,
  ZX_COMPARISON_SERIES_TYPE,
  PUBLIC_COMPARISON_SERIES_TYPE,
  SUPERCHART_API_DATA_FIELDS,
  PRICE_DATA_START_DATES,
  INDICATOR_CATEGORY_OVERALL,
  INDICATOR_CATEGORY_BID_OFFER,
  OLD_INDICATOR_TYPES,
  INDICATOR_TYPES,
  REVERSE_INDICATOR_TYPES,
  COMPARISON_COLORS,
  KEY_COMPANY_COLOR,
  INDICATOR_COLORS,
  LEGEND_Z_INDEX,
  CHART_RIGHT_OFFSET_MULTIPLIER,
  EXCHANGE_DISPLAY_NAMES,
  SERIES_TYPES,
};
