import _ from "lodash";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import styled from "styled-components";
import {
  CheckIcon,
  Fonts,
  LineChartIcon,
  List,
  ListItem,
  PageSection,
  useDropdown,
} from "yuka";

import { ActionButton } from "../../hdYuka";
import { DataverseColors } from "../../hdYuka/constants";
import {
  INDICATOR_CATEGORY_BID_OFFER,
  INDICATOR_CATEGORY_OVERALL,
} from "../../superchart/constants";

const StyledDropdownContainer = styled(PageSection)`
  padding: 12px 0;
  margin: -16px 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const StyledSection = styled(Fonts.Overlinetheme50).attrs({ as: "div" })`
  padding: 8px 12px;
`;

const StyledListItem = styled(ListItem)`
  padding: 0 12px;
  height: 32px;
  min-height: 32px;
`;

const IconPlaceholderDiv = styled.div`
  width: 18px;
  height: 18px;
`;

const AddIndicatorDropdown = ({
  disabled,
  availableIndicators,
  addIndicator,
  removeIndicator,
  selectedIndicators,
}) => {
  const overallIndicators = useMemo(
    () =>
      availableIndicators.filter(
        (indicator) => indicator.category === INDICATOR_CATEGORY_OVERALL
      ),
    [availableIndicators]
  );
  const sideRelatedIndicators = useMemo(
    () =>
      availableIndicators.filter(
        (indicator) => indicator.category === INDICATOR_CATEGORY_BID_OFFER
      ),
    [availableIndicators]
  );

  const sections = useMemo(
    () => [
      {
        title: "Overall",
        indicators: overallIndicators,
      },
      {
        title: "Bid & Ask",
        indicators: sideRelatedIndicators,
      },
    ],
    [overallIndicators, sideRelatedIndicators]
  );

  const IndicatorsDropdown = () => (
    <StyledDropdownContainer>
      {sections.map((section) => (
        <div key={section.title}>
          <StyledSection>{section.title}</StyledSection>
          <List>
            {section.indicators.map((indicator) => {
              const indicatorSelected = selectedIndicators.find(
                (i) => i.name === indicator.id
              );

              return (
                <StyledListItem
                  key={indicator.id}
                  leadingIconColor={DataverseColors.branding400}
                  leadingIcon={
                    indicatorSelected ? CheckIcon : IconPlaceholderDiv
                  }
                  text={indicator.name}
                  onClick={() =>
                    indicatorSelected
                      ? removeIndicator(indicator.id)
                      : addIndicator(indicator.id)
                  }
                />
              );
            })}
          </List>
        </div>
      ))}
    </StyledDropdownContainer>
  );

  const [dropdownElement, dropdownRef, toggleDropdown] =
    useDropdown(IndicatorsDropdown);

  return (
    <>
      <ActionButton
        id="add-indicator-button"
        disabled={disabled}
        active={dropdownElement !== null}
        ref={dropdownRef}
        onClick={toggleDropdown}
        icon={LineChartIcon}
      >
        Indicators
      </ActionButton>
      {dropdownElement}
    </>
  );
};

AddIndicatorDropdown.propTypes = {
  disabled: PropTypes.bool,
  addIndicator: PropTypes.func,
  removeIndicator: PropTypes.func,
  availableIndicators: PropTypes.array,
  selectedIndicators: PropTypes.array,
};

AddIndicatorDropdown.defaultProps = {
  disabled: false,
  availableIndicators: [],
  selectedIndicators: [],
  addIndicator: _.noop,
  removeIndicator: _.noop,
};

export default AddIndicatorDropdown;
