import PropTypes from "prop-types";
import { useEffect, useMemo } from "react";
import { Form } from "react-final-form";
import { useNavigate } from "react-router-dom";
import {
  Button,
  ButtonStyles,
  FinalFormField,
  Fonts,
  Modal,
  ModalStyles,
} from "yuka";

import { StyledModalButtonRow, StyledModalContent } from "./StyledComponents";

import { API_ENDPOINTS } from "../api/constants";
import useWrite from "../api/useWrite";
import { ROUTING_PATH } from "../routes/constants";
import MixpanelEvents from "../utils/mixpanel/MixpanelEvents";

/**
 * Modal to create a new portfolio or rename an existing one.
 *
 * @param source - Mixpanel source.
 * @param {function} onClose - Function to close the modal
 * @param {object} portfolio - Portfolio object to rename, if null, a new portfolio will be created
 * @param {boolean} navigateOnSuccess - If true, navigate to the new portfolio's profile on success
 * @param {object} company - Optional company object to add to the new portfolio upon successful
 *    creation, so the portfolio doesn't start out empty. Should not be provided if renaming a
 *    portfolio, and will not be added in this case.
 * @return {JSX.Element}
 */
const CreatePortfolioModal = ({
  source,
  onClose,
  portfolio,
  navigateOnSuccess,
  company,
}) => {
  const navigate = useNavigate();
  const createPortfolioQuery = useWrite(API_ENDPOINTS.PORTFOLIOS(), false, {
    message: "Portfolio created",
  });
  const addCompanyToPortfolio = useWrite(
    API_ENDPOINTS.PORTFOLIO_COMPANIES(),
    false,
    {
      message: `${company?.name} added to portfolio`,
    }
  );
  const editPortfolioQuery = useWrite(
    API_ENDPOINTS.PORTFOLIO_DETAIL(portfolio?.apiId),
    true,
    {
      invalidateUrls: [{ url: API_ENDPOINTS.PORTFOLIOS(), queryParams: {} }],
    }
  );

  const initialValues = useMemo(
    () =>
      portfolio
        ? {
            name: portfolio.name,
          }
        : {},
    [portfolio]
  );

  useEffect(() => {
    if (!portfolio) {
      MixpanelEvents.openAddNewPortfolioModal(source);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form
      initialValues={initialValues}
      onSubmit={(values) => {
        if (portfolio) {
          editPortfolioQuery.mutate(values, {
            onSuccess: () => {
              MixpanelEvents.renamePortfolio(
                source,
                portfolio.apiId,
                portfolio.name,
                values.name
              );
              onClose();
            },
          });
        } else {
          createPortfolioQuery.mutate(values, {
            onSuccess: (response) => {
              MixpanelEvents.addNewPortfolio(
                source,
                response.data.data.id,
                response.data.data.attributes.name
              );
              if (company && company.zb_id) {
                addCompanyToPortfolio.mutate({
                  portfolio: response.data.data.id,
                  company: company.zb_id,
                });
                MixpanelEvents.addCompanyToPortfolio(
                  source,
                  response.data.data.id,
                  response.data.data.attributes.name,
                  company.name,
                  true
                );
              }
              if (navigateOnSuccess) {
                navigate(ROUTING_PATH.PORTFOLIO(response.data.data.id));
              }
              onClose();
            },
          });
        }
      }}
    >
      {({ handleSubmit }) => (
        <Modal modalStyle={ModalStyles.MINIMAL} onClose={onClose}>
          <StyledModalContent>
            <Fonts.Headline2theme80>
              {portfolio
                ? "Rename your portfolio"
                : "Set up your new portfolio"}
            </Fonts.Headline2theme80>
            <FinalFormField
              ref={(element) => {
                if (element) {
                  element.focus();
                }
              }}
              name="name"
              label="Portfolio name"
              placeholder="Name your portfolio"
            />
          </StyledModalContent>
          <StyledModalButtonRow>
            <Button buttonStyle={ButtonStyles.RAISED} onClick={onClose}>
              Cancel
            </Button>
            <Button buttonStyle={ButtonStyles.CTA} onClick={handleSubmit}>
              {portfolio ? "Save" : "Create"}
            </Button>
          </StyledModalButtonRow>
        </Modal>
      )}
    </Form>
  );
};

CreatePortfolioModal.propTypes = {
  source: PropTypes.string,
  navigateOnSuccess: PropTypes.bool,
  portfolio: PropTypes.shape({
    name: PropTypes.string,
    apiId: PropTypes.string.isRequired,
  }),
  company: PropTypes.shape({
    name: PropTypes.string.isRequired,
    zb_id: PropTypes.string.isRequired,
  }),
  onClose: PropTypes.func.isRequired,
};

CreatePortfolioModal.defaultProps = {
  source: null,
  navigateOnSuccess: true,
  company: null,
  portfolio: null,
};

export default CreatePortfolioModal;
