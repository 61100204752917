import PropTypes from "prop-types";
import { useMemo } from "react";
import { PieChart, Pie, Cell } from "recharts";
import styled, { useTheme } from "styled-components";
import {
  Card,
  List,
  ListStyles,
  Fonts,
  ChevronRightIcon,
  PrimaryColorPalette,
  CardStyles,
  TruncatedText,
} from "yuka";

import { ListItem } from "../../hdYuka";
import {
  usePortfolio,
  usePortfolioValue,
  usePortfoliosContainingCompany,
  usePortfolioCompanyAggregates,
} from "../../Portfolios/hooks";
import { ROUTING_PATH } from "../../routes/constants";
import { percentFormat } from "../../utils/displayFormatUtils";
import MixpanelEvents from "../../utils/mixpanel/MixpanelEvents";
import { StyledCenteredEmptyState } from "../StyledComponents";

const StyledDonutChartContainer = styled.div`
  padding-right: 16px;
`;

const StyledListItem = styled(ListItem)`
  padding: 0 16px;
`;

// for recharts, 0 degrees is at 3 o'clock so we need to adjust the start and end angles
// by 90 degrees to get the correct orientation.
// we go from 450 to 90 degrees to make the chart go clockwise.
const DONUT_START_ANGLE = 450;
const DONUT_END_ANGLE = 90;

const DonutChart = ({ percentage }) => {
  const theme = useTheme();

  const data = [
    { value: percentage, color: theme.colors.branding500 },
    { value: 100 - percentage, color: PrimaryColorPalette.white15 },
  ];
  return (
    <StyledDonutChartContainer>
      <PieChart width={40} height={40}>
        <Pie
          data={data}
          outerRadius={20}
          innerRadius={14}
          dataKey="value"
          startAngle={DONUT_START_ANGLE}
          endAngle={DONUT_END_ANGLE}
        >
          {data.map((entry, index) => (
            <Cell stroke={null} key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
      </PieChart>
    </StyledDonutChartContainer>
  );
};

DonutChart.propTypes = {
  percentage: PropTypes.number.isRequired,
};

const StyledPortfolioName = styled(TruncatedText)`
  max-width: 300px;
`;

const HoldingsListItem = ({ portfolioCompany }) => {
  const portfolioId = portfolioCompany?.portfolio[1];
  const [portfolio, portfolioIsLoading] = usePortfolio(portfolioId);
  const totalValue = usePortfolioValue(portfolioId);
  const aggregates = usePortfolioCompanyAggregates([portfolioCompany]);

  if (portfolioIsLoading || aggregates[0].isLoading) {
    return null;
  }
  const percentage = totalValue
    ? (aggregates[0]?.totalValueFromZXIndexValue / totalValue) * 100
    : 0;

  return (
    <StyledListItem
      listStyle={ListStyles.TWO_LINE}
      leadingContent={<DonutChart percentage={percentage} />}
      text={
        <Fonts.Body2theme80>
          <StyledPortfolioName>{portfolio?.name}</StyledPortfolioName>
        </Fonts.Body2theme80>
      }
      subtext={
        <>
          <Fonts.Body1theme80>{percentFormat(percentage)}</Fonts.Body1theme80>{" "}
          <Fonts.Body1theme50>of this portfolio</Fonts.Body1theme50>
        </>
      }
      trailingContent={<ChevronRightIcon color={PrimaryColorPalette.white50} />}
      url={ROUTING_PATH.PORTFOLIO(portfolioId)}
      onClick={() =>
        MixpanelEvents.clickHoldingsListItem(portfolioCompany.company.name)
      }
    />
  );
};

HoldingsListItem.propTypes = {
  portfolioCompany: PropTypes.shape({
    portfolio: PropTypes.arrayOf(PropTypes.string),
    company: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

const HoldingsCard = ({ company }) => {
  const [portfolioCompany, portfolioCompanyIsLoading] =
    usePortfoliosContainingCompany(company.zb_id);

  const cardContent = useMemo(() => {
    if (portfolioCompanyIsLoading) {
      return (
        <StyledCenteredEmptyState $margin={32}>
          Holdings are loading...
        </StyledCenteredEmptyState>
      );
    }
    if (!portfolioCompany) {
      return (
        <StyledCenteredEmptyState $margin={32}>
          An error has occurred.
        </StyledCenteredEmptyState>
      );
    }
    if (portfolioCompany.length === 0) {
      return (
        <StyledCenteredEmptyState $margin={32}>
          No holdings of this company
        </StyledCenteredEmptyState>
      );
    }
    return (
      <List>
        {portfolioCompany.map((portfolioCompany, index) => (
          <HoldingsListItem key={index} portfolioCompany={portfolioCompany} />
        ))}
      </List>
    );
  }, [portfolioCompany, portfolioCompanyIsLoading]);

  return (
    <Card
      title={`Your holdings of ${company.name}`}
      cardStyle={CardStyles.SECTIONED}
    >
      {cardContent}
    </Card>
  );
};

HoldingsCard.propTypes = {
  company: PropTypes.shape({
    name: PropTypes.string.isRequired,
    zb_id: PropTypes.string.isRequired,
  }).isRequired,
};

export default HoldingsCard;
