import PropTypes from "prop-types";
import { useMemo } from "react";
import { Fonts, XCloseIcon } from "yuka";

import { LegendItemContainer, StyledCircle } from "./StyledComponents";

import { DataverseColors } from "../../hdYuka/constants";
import ToggleableIcon from "../../superchart/SuperchartLegend/ToggleableIcon";
import useChartReferencePoint from "../../superchart/SuperchartLegend/useChartReferencePoint";
import { shortMoneyFormat } from "../../utils/displayFormatUtils";

const EmptyVolume = (label) => (
  <Fonts.Body1theme80>
    {label} - $<Fonts.Body1theme30>--</Fonts.Body1theme30>
  </Fonts.Body1theme80>
);

const VolumeAggregateLegendItem = ({
  isHovered,
  chartElement,
  onMouseEnter,
  onMouseLeave,
  hoveredDate,
  onRemove,
}) => {
  const { data } = chartElement;
  const referencePoint = useChartReferencePoint(
    hoveredDate,
    data,
    "total",
    true
  );

  /*
   * The values from the data point is stored in "millions", so we'll reformat it appropriately.
   * Typically we'd just append an "M" to it but when it's larger than 1000M, we really want to
   * show a "B".
   */
  const values = useMemo(() => {
    if (!referencePoint) {
      return {
        total: null,
        bid: null,
        offer: null,
      };
    }
    return {
      total: referencePoint.total
        ? shortMoneyFormat(referencePoint.total * 1_000_000)
        : null,
      bid: referencePoint.bid
        ? shortMoneyFormat(referencePoint.bid * 1_000_000)
        : null,
      offer: referencePoint.offer
        ? shortMoneyFormat(referencePoint.offer * 1_000_000)
        : null,
    };
  }, [referencePoint]);

  const formattedTotalValue = useMemo(() => {
    if (!values.total) {
      return EmptyVolume("Total");
    }
    return <Fonts.Body1theme80>Total - {values.total}</Fonts.Body1theme80>;
  }, [values.total]);

  const formattedBidValue = useMemo(() => {
    if (!values.bid) {
      return EmptyVolume("Bid");
    }
    return <Fonts.Body1theme80>Bid - {values.bid}</Fonts.Body1theme80>;
  }, [values.bid]);

  const formattedOfferValue = useMemo(() => {
    if (!values.offer) {
      return EmptyVolume("Offer");
    }
    return <Fonts.Body1theme80>Offer - {values.offer}</Fonts.Body1theme80>;
  }, [values.offer]);

  return (
    <LegendItemContainer
      $isHovered={isHovered}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Fonts.Body1theme80>Volume - 3M AGG.</Fonts.Body1theme80>
      {formattedTotalValue}
      <StyledCircle color={DataverseColors.green} />
      {formattedBidValue}
      <StyledCircle color={DataverseColors.red} />
      {formattedOfferValue}
      {onRemove ? (
        <ToggleableIcon icon={XCloseIcon} onClick={onRemove} />
      ) : null}
    </LegendItemContainer>
  );
};

VolumeAggregateLegendItem.propTypes = {
  isHovered: PropTypes.bool,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onRemove: PropTypes.func,
  chartElement: PropTypes.shape({
    data: PropTypes.shape({}),
  }).isRequired,
  hoveredDate: PropTypes.string,
};

export default VolumeAggregateLegendItem;
