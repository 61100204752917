import PropTypes from "prop-types";
import React from "react";
import { Form } from "react-final-form";
import { Fonts, ButtonStyles, HyperLink, LinkTypes, LinkStyles } from "yuka";

import {
  AuthButton,
  AuthFormInput,
  AuthFormInputErrorTrailingContent,
  FormContents,
} from "./StyledComponents";

import { AcceptAgreementsFields } from "../form-fields";
import { ROUTING_PATH } from "../routes/constants";
import LoadingSpinner from "../utils/LoadingSpinner";
import PasswordSecurityTips from "../utils/PasswordSecurityTips";
import useUnfocusOnSubmit from "../utils/useUnfocusOnSubmit";

const SetNewPassword = (props) => {
  const [onSubmit, submitRef] = useUnfocusOnSubmit(props.onSubmit);

  return (
    <React.Fragment>
      <PasswordSecurityTips />
      <Form onSubmit={onSubmit}>
        {({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <FormContents>
              {submitting && <LoadingSpinner />}
              <AuthFormInput
                id="password"
                type="password"
                name="password"
                placeholder="New password"
                $error={props.errors.password}
                onChange={() =>
                  props.setErrors({ ...props.errors, password: undefined })
                }
                trailingIcon={() =>
                  props.errors.password ? (
                    <AuthFormInputErrorTrailingContent>
                      {props.errors.password}
                    </AuthFormInputErrorTrailingContent>
                  ) : null
                }
              />
              <AuthFormInput
                id="confirm-password"
                type="password"
                name="confirm_password"
                placeholder="Confirm your new password"
                displayIndicator={false}
                $error={props.errors.confirm_password}
                onChange={() =>
                  props.setErrors({
                    ...props.errors,
                    confirm_password: undefined,
                  })
                }
                trailingIcon={() =>
                  props.errors.confirm_password ? (
                    <AuthFormInputErrorTrailingContent>
                      {props.errors.confirm_password}
                    </AuthFormInputErrorTrailingContent>
                  ) : null
                }
              />
              {props.includeAgreements && <AcceptAgreementsFields />}
              {props.errors.submission && (
                <Fonts.Body2sell>{props.errors.submission}</Fonts.Body2sell>
              )}
              <AuthButton
                ref={submitRef}
                buttonStyle={ButtonStyles.CTA}
                disabled={submitting}
                type="submit"
              >
                Set password
              </AuthButton>
              <HyperLink
                linkStyle={LinkStyles.SUPPORT}
                linkType={LinkTypes.LOCAL_LINK}
                url={ROUTING_PATH.LOGIN()}
              >
                <Fonts.Body2theme50>Back to Login</Fonts.Body2theme50>
              </HyperLink>
            </FormContents>
          </form>
        )}
      </Form>
    </React.Fragment>
  );
};

SetNewPassword.propTypes = {
  errors: PropTypes.shape({
    password: PropTypes.string,
    confirm_password: PropTypes.string,
    submission: PropTypes.string,
  }).isRequired,
  setErrors: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  includeAgreements: PropTypes.bool.isRequired,
};

export default SetNewPassword;
