import _ from "lodash";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ButtonStyles, Fonts, YukaThemeProvider } from "yuka";

import {
  BidAskRatioCard,
  CompanyDocumentsCard,
  HoldingsCard,
  RecentCustomValuationsCard,
  FundingCard,
  CompanyTransferabilityCard,
  CompanyNewsCard,
  ReportedMarksCard,
} from "./cards";
import AboutCard from "./cards/AboutCard";
import CompanyHighLevelStats from "./CompanyHighLevelStats";
import CompanyProfileHeader from "./CompanyProfileHeader";
import { CompanyProfileLineGraph } from "./CompanyProfileLineGraph";
import CompanyProfileNoAccess from "./CompanyProfileNoAccess";
import { useCompany } from "./hooks";
import useCompanyAccess from "./hooks/useCompanyAccess";
import MostActiveCompanies from "./MostActiveCompanies";
import SimilarCompanies from "./SimilarCompanies";

import { API_ENDPOINTS, SACRA_FEATURE_NAME } from "../api/constants";
import useWrite from "../api/useWrite";
import { AuthContext } from "../auth";
import useHasFeatureAccess from "../company/hooks/useHasFeatureAccess";
import { PAGE_WIDTH } from "../constants";
import { pushRecentlyVisitedCompanies } from "../global-header/utils";
import { KEY_ACTION_BUTTON_CONTAINER } from "../hdYuka/yukaThemeOverrides";
import { ROUTING_PATH } from "../routes/constants";
import {
  COMPANY_PROFILE_MIXPANEL_TARGET,
  useMpSource,
} from "../routes/StateProvider";
import SiteFooter from "../SiteFooter/component";
import LoadingSpinner from "../utils/LoadingSpinner";
import MixpanelEvents from "../utils/mixpanel/MixpanelEvents";
import useScrollable from "../utils/useScrollable";
import { Walkthrough } from "../Walkthrough";

const CompanyProfileContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  margin-top: -16px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CompanyProfileLayout = styled.div`
  ${PAGE_WIDTH};

  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;

  > * {
    flex-shrink: 0;
  }
`;

const StyledColumnSection = styled.div`
  display: grid;
  gap: 24px;
  grid-template-columns: 2fr 1fr;
  grid-template-areas:
    "general user"
    "mostactive mostactive"
    "sitefooter sitefooter";

  > :first-child {
    grid-area: general;
  }
  > :nth-child(2) {
    grid-area: user;
  }
  > :nth-child(3) {
    grid-area: mostactive;
  }
  > :nth-child(4) {
    grid-area: sitefooter;
  }
`;

const StyledGeneralCompanyInformation = styled.div`
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "bidofferratio reportedmarks"
    "fundings fundings"
    "transferability transferability"
    "mentions mentions"
    "news news";

  > :first-child {
    grid-area: bidofferratio;
  }
  > :nth-child(2) {
    grid-area: reportedmarks;
  }
  > :nth-child(3) {
    grid-area: fundings;
  }
  > :nth-child(4) {
    grid-area: transferability;
  }
  > :nth-child(5) {
    grid-area: mentions;
  }
  > :nth-child(6) {
    grid-area: news;
  }
`;

const StyledNoAccess = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const StyledUserCompanyInformation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const COMPANY_PROFILE_HEADER_BREAKPOINT = 232;

const WALKTHROUGH_STEPS = [
  {
    title: "Welcome to the new company profile",
    text:
      "Our redesigned company profile offers a more intuitive and informative experience, " +
      "providing a deeper range of data points and insights. Discover new opportunities and " +
      "make more informed investment decisions with our revamped company profiles.",
    positionSelector: null,
    width: 600,
  },
  {
    title: "Compare and contrast",
    text:
      "Benchmark this company against other private companies, public symbols, and our ZX " +
      "private market indices to gain a better understanding of its performance over time.",
    positionSelector: "#add-comparison-button",
  },
  {
    title: "Dive deeper with indicators",
    text: "Add indicators to enhance your analysis.",
    positionSelector: "#add-indicator-button",
  },
  {
    title: "Stay informed",
    text:
      "Easily monitor your holdings or stay informed about this company by adding it to one of " +
      "your portfolios.",
    positionSelector: "#add-company-to-portfolio-button",
  },
];

const CompanyProfile = () => {
  const navigate = useNavigate();
  const source = useMpSource(COMPANY_PROFILE_MIXPANEL_TARGET);
  const containerRef = useRef(null);
  const { user, setUserData } = useContext(AuthContext);
  const walkthroughUpdate = useWrite(
    API_ENDPOINTS.WALKTHROUGH_PROGRESS(user?.id),
    true,
    {
      silent: true,
    }
  );
  const [walkthroughShowing, setWalkthroughShowing] = useState(
    !user?.walkthrough_progress?.walkthrough_company_profile
  );
  const [, scrollToTop, , distanceFromTop] = useScrollable({
    scrollRef: containerRef,
    onScrollUp: _.noop,
    onScrollDown: _.noop,
  });

  const [company, companyLoading] = useCompany();

  useEffect(() => {
    // Scroll to top when the company name changes, since we can navigate to other companies
    // within this profile using LOCAL_LINK hyperlinks, which won't trigger a rerender all the time.
    if (company?.name) {
      scrollToTop({ animate: false });
    }
  }, [company?.name, scrollToTop]);

  const [sacraDocumentsFeatureEnabled] =
    useHasFeatureAccess(SACRA_FEATURE_NAME);

  const { hasAnyAccess, canViewSacraDocuments, isLoadingAccess } =
    useCompanyAccess(company?.zb_id);

  useEffect(() => {
    if (!companyLoading && company !== null) {
      pushRecentlyVisitedCompanies(company);
      MixpanelEvents.viewCompanyProfile(source, company.name);
    }
    if (!companyLoading && company === null) {
      navigate(ROUTING_PATH.COMPANIES());
    }
  }, [source, navigate, companyLoading, company]);

  if (companyLoading || isLoadingAccess) {
    return <LoadingSpinner />;
  }

  if (!company) {
    return (
      <CompanyProfileContainer>
        <Fonts.Body1theme30>An error has occurred.</Fonts.Body1theme30>
      </CompanyProfileContainer>
    );
  }

  return (
    <YukaThemeProvider
      theme={{
        button: {
          [ButtonStyles.CTA]: {
            container: KEY_ACTION_BUTTON_CONTAINER,
          },
          [ButtonStyles.RAISED]: {
            container: KEY_ACTION_BUTTON_CONTAINER,
          },
        },
      }}
    >
      {hasAnyAccess &&
        walkthroughShowing &&
        !user?.walkthrough_progress?.walkthrough_company_profile && (
          <Walkthrough
            name="company profile"
            steps={WALKTHROUGH_STEPS}
            onFinish={() => {
              setWalkthroughShowing(false);
              walkthroughUpdate.mutate(
                {
                  walkthrough_company_profile: true,
                },
                {
                  onSuccess: () => {
                    setUserData();
                  },
                }
              );
            }}
          />
        )}
      <CompanyProfileContainer
        $floatingHeader={distanceFromTop >= COMPANY_PROFILE_HEADER_BREAKPOINT}
        ref={containerRef}
      >
        <CompanyProfileHeader
          floating
          hidden={distanceFromTop < COMPANY_PROFILE_HEADER_BREAKPOINT}
        />
        <CompanyProfileLayout>
          <CompanyProfileHeader floating={false} hidden={false} />
          {hasAnyAccess ? (
            <>
              <CompanyProfileLineGraph />
              <CompanyHighLevelStats />
              <StyledColumnSection>
                <StyledGeneralCompanyInformation>
                  <BidAskRatioCard />
                  <ReportedMarksCard />
                  <FundingCard />
                  <CompanyTransferabilityCard />
                  {sacraDocumentsFeatureEnabled &&
                    company.show_sacra_documents &&
                    canViewSacraDocuments && <CompanyDocumentsCard />}
                  <CompanyNewsCard />
                </StyledGeneralCompanyInformation>
                <StyledUserCompanyInformation>
                  <HoldingsCard company={company} />
                  <RecentCustomValuationsCard company={company} />
                  <AboutCard />
                </StyledUserCompanyInformation>
                <SimilarCompanies />
                <MostActiveCompanies />
                <SiteFooter />
              </StyledColumnSection>
            </>
          ) : (
            <StyledNoAccess>
              <CompanyProfileNoAccess />
              <SiteFooter />
            </StyledNoAccess>
          )}
        </CompanyProfileLayout>
      </CompanyProfileContainer>
    </YukaThemeProvider>
  );
};

export default CompanyProfile;
