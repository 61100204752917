import _ from "lodash";
import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { DateTime } from "luxon";
import {
  Card,
  CardStyles,
  CardHeader,
  FontColors,
  body1,
  HyperLink,
  Modal,
  ModalStyles,
  Table,
  Fonts,
  List,
  ListItem,
  LinkExternalIcon,
  ColorPalette,
  LinkTypes,
} from "yuka";

import { StyledError } from "../utils/StyledComponents";

import useInfiniteFetch from "../api/useInfiniteFetch";
import { API_ENDPOINTS } from "../api/constants";
import LoadingSpinner from "../utils/LoadingSpinner";

import FundingRoundTable from "./FundingRoundTable";

import useCompanyFetch from "./utils/useCompanyFetch";
import useEntityProfileFetches from "./utils/useEntityProfileFetches";
import MixpanelEvents from "../utils/mixpanel/MixpanelEvents";
import FundingRoundsStat from "./FundingRoundsStat";
import investorNameSort from "./utils/investorNameSort";
import { shortMoneyFormat } from "../utils/displayFormatUtils";
import useFetch from "../api/useFetch";

const StyledNoTableData = styled.div`
  ${body1};
  ${FontColors.theme50}
  margin: 32px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EmptyInfoContainer = styled.div`
  padding: 0 16px;
`;

const StyledFundingRoundsHeader = styled.div`
  margin: 0 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyledStatsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 16px;
  column-gap: 64px;
`;

const StyledModalContent = styled.div`
  height: 450px;
  width: 600px;
  margin: -24px -16px;
`;

const INVESTORS_MODAL = "INVESTORS_MODAL";
const COI_MODAL = "COI_MODAL";

const CompanyFundingRoundsCard = (props) => {
  const companyQuery = useCompanyFetch(props.companyId);
  const company = companyQuery?.cleanedData?.data;

  const [currentModal, setCurrentModal] = useState(null);

  // Using a hack page size here that a query to the funding rounds/stock split table for a given
  // company will not exceed in practice. There is great difficulty with combining multiple models
  // into 1 resultant queryset + using pagination. This is a hack to get around that.
  const hdFundingRoundsReq = useInfiniteFetch(
    API_ENDPOINTS.HD_FUNDING_ROUNDS(),
    {
      company: props.companyId,
      "page[size]": 100000,
    }
  );

  const stockSplitsReq = useInfiniteFetch(API_ENDPOINTS.STOCK_SPLITS(), {
    company: props.companyId,
    "page[size]": 100000,
  });

  const fundingRounds = useMemo(() => {
    return hdFundingRoundsReq.isSuccess
      ? hdFundingRoundsReq.cleanedData.data
      : [];
  }, [hdFundingRoundsReq.isSuccess, hdFundingRoundsReq.cleanedData.data]);

  const totalPreferredRounds = useMemo(() => {
    return fundingRounds.filter((round) =>
      round.security?.toLowerCase().includes("preferred")
    ).length;
  }, [fundingRounds]);

  const totalFundingAmount = useMemo(
    () =>
      fundingRounds.reduce(
        (acc, round) => acc + Number(round.amount_raised) || 0,
        0
      ),
    [fundingRounds]
  );

  const stockSplits = useMemo(
    () => (stockSplitsReq.isSuccess ? stockSplitsReq.cleanedData.data : []),
    [stockSplitsReq.isSuccess, stockSplitsReq.cleanedData.data]
  );

  const entityProfilesQuery = useEntityProfileFetches(fundingRounds);
  const entityProfiles = useMemo(
    () =>
      entityProfilesQuery.isAnySuccess
        ? _.keyBy(entityProfilesQuery?.cleanedData.data || [], "apiId")
        : {},
    [entityProfilesQuery.isAnySuccess, entityProfilesQuery.cleanedData]
  );

  const entityProfileTableData = useMemo(() => {
    // For each entity profile, map them to the funding rounds they participated in
    return Object.values(
      _.mapValues(entityProfiles, (entityProfile) => ({
        investor: entityProfile.name,
        fundingRounds: _.filter(
          _.map(
            _.filter(fundingRounds, (fundingRound) =>
              _.some(
                fundingRound.investors,
                (investor) => investor[1] === entityProfile.apiId
              )
            ),
            (fundingRound) => fundingRound.event
          )
        ),
      }))
    ).sort(investorNameSort);
  }, [entityProfiles, fundingRounds]);

  const coiFilings = useFetch(API_ENDPOINTS.COI_FILINGS(), {
    company: props.companyId,
  });
  const companyCOIs = coiFilings.cleanedData?.data;

  const numFundingRounds = fundingRounds ? fundingRounds.length : 0;
  const loading = hdFundingRoundsReq.isLoading || stockSplitsReq.isLoading;

  let tableContent = null;
  if (loading) {
    tableContent = <LoadingSpinner absolute={false} />;
  } else if (hdFundingRoundsReq.isError || stockSplitsReq.isError) {
    tableContent = (
      <EmptyInfoContainer>
        <StyledError>An error has occurred.</StyledError>
      </EmptyInfoContainer>
    );
  } else {
    const grouped = [];
    let currGroupedFundingRounds = [];
    let currNumFundingRounds = 0;
    let currRoundIndex = 0;
    let currSplitIndex = 0;
    while (
      currNumFundingRounds < numFundingRounds &&
      currSplitIndex < stockSplits.length
    ) {
      const currRound = fundingRounds[currRoundIndex];
      const currSplit = stockSplits[currSplitIndex];
      if (currRound.order > currSplit.order) {
        // part of the funding round table
        currGroupedFundingRounds.push(currRound);
        currRoundIndex += 1;
        currNumFundingRounds += 1;
      } else {
        // break up the funding round table and insert a row for the stock split
        grouped.push(currGroupedFundingRounds);
        grouped.push(currSplit);
        currGroupedFundingRounds = [];
        currSplitIndex += 1;
      }
    }
    if (currNumFundingRounds === numFundingRounds) {
      // at the end, attach the last batch of included funding rounds
      if (currGroupedFundingRounds.length > 0) {
        grouped.push(currGroupedFundingRounds);
      }
    } else if (currNumFundingRounds < numFundingRounds) {
      const numNeeded = numFundingRounds - currNumFundingRounds;
      grouped.push(
        fundingRounds.slice(currRoundIndex, currRoundIndex + numNeeded)
      );
    }

    const nextSplit = stockSplits[currSplitIndex];
    if (nextSplit) {
      grouped.push(nextSplit);
    }

    tableContent = (
      <FundingRoundTable
        entityProfiles={entityProfiles}
        fundingRounds={_.flatten(grouped)}
        emptyTablePlaceholder={
          <StyledNoTableData>Funding rounds not available</StyledNoTableData>
        }
      />
    );
  }

  return (
    <Card cardStyle={CardStyles.SECTIONED}>
      <CardHeader
        padded
        headline="Funding Rounds"
        linkText={
          companyCOIs?.length > 0 ? "View certificates of incorporation" : null
        }
        linkProps={{
          onClick: () => {
            MixpanelEvents.viewCompanyCertificatesOfIncorporationList(
              company?.name
            );
            setCurrentModal(COI_MODAL);
          },
        }}
      />
      <StyledFundingRoundsHeader>
        <StyledStatsRow>
          <FundingRoundsStat
            mainStatistic={shortMoneyFormat(totalFundingAmount)}
            supportingText="Total funding amount"
          />
          <FundingRoundsStat
            mainStatistic={totalPreferredRounds}
            supportingText="Funding rounds"
          />
          <FundingRoundsStat
            mainStatistic={
              entityProfilesQuery.isLoading
                ? "Loading"
                : Object.keys(entityProfiles).length
            }
            supportingText={
              <span>
                Investors{" "}
                {Object.keys(entityProfiles).length !== 0 ? (
                  <>
                    (
                    <HyperLink onClick={() => setCurrentModal(INVESTORS_MODAL)}>
                      see all
                    </HyperLink>
                    )
                  </>
                ) : null}
              </span>
            }
          />
        </StyledStatsRow>
        <Fonts.Caption2theme30>
          Data is collected from state filings and publicly available sources,
          and may be incomplete.
        </Fonts.Caption2theme30>
      </StyledFundingRoundsHeader>
      <div>{tableContent}</div>
      {currentModal === INVESTORS_MODAL && (
        <Modal
          title={`${company?.name || ""} Investors`}
          onClose={() => setCurrentModal(null)}
          modalStyle={ModalStyles.SCROLLABLE}
        >
          <StyledModalContent>
            <Table
              usePercentageColumnWidths
              columns={[
                {
                  id: "investorName",
                  header: "Investor",
                  accessor: "investor",
                  width: 40,
                  sortable: true,
                },
                {
                  id: "fundingRounds",
                  header: "Funding Rounds",
                  accessor: (entity) => entity.fundingRounds.join(", "),
                  width: 60,
                  sortable: true,
                },
              ]}
              data={entityProfileTableData}
            />
          </StyledModalContent>
        </Modal>
      )}
      {currentModal === COI_MODAL && (
        <Modal
          title={`${company?.name || ""} Certificates of Incorporation`}
          onClose={() => setCurrentModal(null)}
          modalStyle={ModalStyles.SCROLLABLE}
        >
          <StyledModalContent>
            <List divider>
              {companyCOIs.map((coi) => (
                <ListItem
                  key={coi.id}
                  text={`Filed on ${DateTime.fromISO(
                    coi.filing_date
                  ).toLocaleString(DateTime.DATE_FULL)}`}
                  trailingContent={
                    <LinkExternalIcon color={ColorPalette.white50} />
                  }
                  onClick={() =>
                    MixpanelEvents.viewCompanyCertificateOfIncorporation(
                      company?.name,
                      coi.filing_date
                    )
                  }
                  url={coi.file_kn_url}
                  linkType={LinkTypes.EXTERNAL_LINK}
                />
              ))}
            </List>
          </StyledModalContent>
        </Modal>
      )}
    </Card>
  );
};

CompanyFundingRoundsCard.propTypes = {
  companyId: PropTypes.string.isRequired,
};

export default CompanyFundingRoundsCard;
