import _ from "lodash";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import styled from "styled-components";
import {
  Table,
  DateCell,
  NumberCell,
  HyperLink,
  List,
  ListItem,
  Fonts,
  BadgeStyles,
  FontColors,
  LinkStyles,
  YukaThemeProvider,
  ColorPalette,
  Button,
  ButtonStyles,
  PageSection,
} from "yuka";

import {
  FundingRoundOrStockSplitCellRenderer,
  FundingRoundEventCellRenderer,
} from "./CellRenderers";
import {
  EVENT_NAME_COLUMN_ID,
  STOCK_SPLIT,
  FundingTableStyles,
  INITIAL_TABLE_SIZE,
  EMPTY_STATE_MARGIN,
  TAB_MAP,
} from "./constants";
import { StyledButtonContainer } from "./StyledComponents";
import { investorNameSort } from "./utils";

import { useDropdown } from "../../../hdYuka";
import addCommasToNum from "../../../utils/addCommasToNum";
import {
  expandedMoneyFormat,
  shortMoneyFormat,
} from "../../../utils/displayFormatUtils";
import { useCompany } from "../../hooks";
import { StyledCenteredEmptyState } from "../../StyledComponents";
import MixpanelEvents from "../../../utils/mixpanel/MixpanelEvents";

const FUNDING_ROUND_MIN_DECIMAL_PLACES = 2;
const FUNDING_ROUND_MAX_DECIMAL_PLACES = 4;
const EMPTY_VALUE_PLACEHOLDER = <Fonts.Body1theme50>--</Fonts.Body1theme50>;

const ENTITY_LOADING = "Loading...";

const fundingRoundTableColumns = (hasWaterfallDistribution) => {
  const columns = [
    {
      id: EVENT_NAME_COLUMN_ID,
      width: 200,
      sticky: true,
      header: "Event",
      accessor: (fundingRound) => fundingRound,
      cellRenderer: FundingRoundOrStockSplitCellRenderer,
      badgeMapping: {},
      defaultBadgeStyle: BadgeStyles.MEDIUM_GRAY,
      fundingRoundCellRenderer: FundingRoundEventCellRenderer,
    },
    {
      id: "date",
      width: 100,
      header: "Date",
      accessor: (fundingRound) => fundingRound,
      emptyPlaceholder: EMPTY_VALUE_PLACEHOLDER,
      cellRenderer: FundingRoundOrStockSplitCellRenderer,
      fundingRoundCellRenderer: ({ value: fundingRound, ...props }) => (
        <DateCell value={fundingRound.date} {...props} />
      ),
      dateFormatter: (value) => {
        const formattedDate = DateTime.fromISO(value).toLocaleString({
          month: "short",
          year: "2-digit",
        });
        const [month, year] = formattedDate.split(" ");
        // Inject the apostrophe to the year.
        return `${month} '${year}`;
      },
    },
    {
      id: "pricePerShare",
      width: 140,
      header: "Price/Share",
      accessor: (fundingRound) => fundingRound,
      cellRenderer: FundingRoundOrStockSplitCellRenderer,
      fundingRoundCellRenderer: NumberCell,
      formatter: (fundingRound) =>
        fundingRound.price_per_share
          ? expandedMoneyFormat(
              fundingRound.price_per_share,
              FUNDING_ROUND_MIN_DECIMAL_PLACES,
              FUNDING_ROUND_MAX_DECIMAL_PLACES
            )
          : EMPTY_VALUE_PLACEHOLDER,
    },
    {
      id: "amountRaised",
      width: 160,
      header: "Amount Raised",
      accessor: (fundingRound) => fundingRound,
      cellRenderer: FundingRoundOrStockSplitCellRenderer,
      fundingRoundCellRenderer: NumberCell,
      formatter: (fundingRound) =>
        fundingRound.amount_raised
          ? shortMoneyFormat(
              fundingRound.amount_raised,
              FUNDING_ROUND_MIN_DECIMAL_PLACES
            )
          : EMPTY_VALUE_PLACEHOLDER,
    },
    {
      id: "postmoneyValuation",
      width: 124,
      header: "Post-val",
      accessor: (fundingRound) => fundingRound,
      cellRenderer: FundingRoundOrStockSplitCellRenderer,
      fundingRoundCellRenderer: NumberCell,
      formatter: (fundingRound) =>
        fundingRound.postmoney_valuation
          ? shortMoneyFormat(
              fundingRound.postmoney_valuation,
              FUNDING_ROUND_MIN_DECIMAL_PLACES
            )
          : EMPTY_VALUE_PLACEHOLDER,
    },
    {
      id: "investorList",
      width: 124,
      accessor: (fundingRound) => fundingRound,
      header: "Investor List",
      cellRenderer: FundingRoundOrStockSplitCellRenderer,
      fundingRoundCellRenderer: ({
        value: fundingRound,
        triggerRef,
        toggleDisplay,
        entityProfiles,
      }) => {
        // Determine if we've loaded all the investor profiles.
        const allInvestorsLoaded =
          fundingRound.investors?.length > 0 &&
          _.some(
            fundingRound.investors,
            (investor) => investor[1] in entityProfiles
          );

        return allInvestorsLoaded ? (
          <HyperLink
            ref={triggerRef}
            linkStyle={
              fundingRound.parent_funding_round
                ? LinkStyles.INVISIBLE
                : LinkStyles.DEFAULT
            }
            onClick={() => toggleDisplay(fundingRound)}
          >
            {fundingRound.investors.length} Investor
            {fundingRound.investors.length > 1 ? "s" : ""}
          </HyperLink>
        ) : (
          EMPTY_VALUE_PLACEHOLDER
        );
      },
    },
    {
      id: "authorizedShares",
      width: 156,
      header: "Shares Authorized",
      accessor: (fundingRound) => fundingRound,
      cellRenderer: FundingRoundOrStockSplitCellRenderer,
      fundingRoundCellRenderer: NumberCell,
      formatter: (fundingRound) =>
        fundingRound.authorized_shares
          ? addCommasToNum(fundingRound.authorized_shares)
          : EMPTY_VALUE_PLACEHOLDER,
    },
    {
      id: "security",
      width: 188,
      header: "Security",
      accessor: (fundingRound) => fundingRound,
      cellRenderer: FundingRoundOrStockSplitCellRenderer,
      fundingRoundCellRenderer: ({ value: fundingRound }) =>
        fundingRound.security || EMPTY_VALUE_PLACEHOLDER,
    },
    {
      id: "liquidationMultiple",
      width: 124,
      header: "Multiple",
      accessor: (fundingRound) => fundingRound,
      cellRenderer: FundingRoundOrStockSplitCellRenderer,
      fundingRoundCellRenderer: ({ value: fundingRound }) =>
        fundingRound?.liquidation_display || EMPTY_VALUE_PLACEHOLDER,
    },
  ];
  if (hasWaterfallDistribution) {
    columns.push({
      id: "distributionOrder",
      width: 200,
      header: "Waterfall Distribution",
      accessor: (fundingRound) => fundingRound,
      cellRenderer: FundingRoundOrStockSplitCellRenderer,
      fundingRoundCellRenderer: ({ value: fundingRound }) =>
        fundingRound?.liquidation_order_display || EMPTY_VALUE_PLACEHOLDER,
    });
  }
  return columns;
};

const StyledList = styled(List)`
  height: unset;
  max-height: 400px;
  margin: -16px 0; // dropdown component has padding that can't be overwritten
  padding: 0 16px;
  width: 228px;
`;

const StyledListItem = styled(ListItem)`
  border-bottom: 1px dashed ${ColorPalette.white15};
  padding: 0;
`;

const EntityName = styled.span`
  ${({ $loading }) => ($loading ? FontColors.theme50 : FontColors.theme80)};
`;

/**
 * A table listing all funding rounds for the given company.
 *
 * @param {object} props
 * @returns {React.Component}
 */
const FundingRoundTable = (props) => {
  const [displayFundingRoundInvestors, setDisplayFundingRoundInvestors] =
    useState({});
  const [isShowingMore, setIsShowingMore] = useState(false);
  const [company] = useCompany();
  const numberOfRows = isShowingMore
    ? props.fundingRounds.length
    : INITIAL_TABLE_SIZE;
  const sortedFundingRounds = useMemo(() => {
    // Sorts the funding rounds by `order` (descending), except for funding rounds with a
    // populated `parent_funding_round` field, which will be inserted directly below their parent,
    // also sorted by descending `order`.
    // Example of outcome:
    // Series C        (order 5)
    // Series B        (order 2)
    //    Series B-2   (order 4)   <- child of Series B
    //    Series B-1   (order 3)   <- child of Series B
    // Series A        (order 1)
    // Funding rounds are received in the props already sorted by order, so we start by just taking
    // all the parent funding rounds.
    const parentFundingRounds = props.fundingRounds.filter(
      (round) =>
        round.apiType === STOCK_SPLIT || round.parent_funding_round === null
    );
    const finalSortedFundingRounds = [];
    // Next we inject the children of each of the parents directly below them.
    parentFundingRounds.forEach((parentRound) => {
      finalSortedFundingRounds.push(parentRound);
      const children = props.fundingRounds.filter(
        (round) => round.parent_funding_round?.[1] === parentRound.apiId
      );
      finalSortedFundingRounds.push(...children);
    });
    return finalSortedFundingRounds;
  }, [props.fundingRounds]);

  const hasWaterfallDistribution = useMemo(() => {
    const hasValues = (fundingRound) => fundingRound.liquidation_order_display;
    return sortedFundingRounds.some(hasValues);
  }, [sortedFundingRounds]);

  const investorList = useMemo(() => {
    if (!_.isEmpty(displayFundingRoundInvestors)) {
      return displayFundingRoundInvestors.investors
        .map((investor) => ({
          investor: props.entityProfiles[investor[1]]?.name || ENTITY_LOADING,
        }))
        .sort(investorNameSort);
    }
    return [];
  }, [displayFundingRoundInvestors, props.entityProfiles]);

  const [dropdownElement, triggerRef, toggleDisplay] = useDropdown(() => (
    <StyledList>
      <StyledListItem
        key="header"
        text={
          <Fonts.Overlinetheme50>
            {displayFundingRoundInvestors.event} Investors
          </Fonts.Overlinetheme50>
        }
      />
      {investorList.map((investor, index) => (
        <StyledListItem
          key={`investor_${index}`}
          text={
            <EntityName $loading={investor.investor === ENTITY_LOADING}>
              {investor.investor}
            </EntityName>
          }
        />
      ))}
    </StyledList>
  ));

  if (sortedFundingRounds.length === 0) {
    return (
      <StyledCenteredEmptyState $margin={EMPTY_STATE_MARGIN}>
        Funding round data not available
      </StyledCenteredEmptyState>
    );
  }

  return (
    <YukaThemeProvider theme={{ tableStyles: FundingTableStyles }}>
      <Table
        columns={fundingRoundTableColumns(hasWaterfallDistribution)}
        data={sortedFundingRounds.slice(0, numberOfRows)}
        entityProfiles={props.entityProfiles}
        triggerRef={triggerRef}
        dropdownElement={dropdownElement}
        toggleDisplay={(fundingRound) => {
          toggleDisplay();
          setDisplayFundingRoundInvestors(fundingRound);
        }}
      />
      {sortedFundingRounds.length > INITIAL_TABLE_SIZE && (
        <StyledButtonContainer>
          <Button
            buttonStyle={ButtonStyles.RAISED}
            onClick={() => {
              MixpanelEvents.clickFundingsShowMore(
                company?.name,
                TAB_MAP.FUNDING_ROUNDS,
                !isShowingMore
              );
              setIsShowingMore(!isShowingMore);
            }}
          >
            {isShowingMore ? "Show fewer" : "Show more"}
          </Button>
        </StyledButtonContainer>
      )}
      {dropdownElement && <PageSection>{dropdownElement}</PageSection>}
    </YukaThemeProvider>
  );
};

FundingRoundTable.propTypes = {
  entityProfiles: PropTypes.shape({
    [PropTypes.string]: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
  fundingRounds: PropTypes.arrayOf(PropTypes.object).isRequired,
};

FundingRoundTable.defaultProps = {
  entityProfiles: {},
};

export default FundingRoundTable;
